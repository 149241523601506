import React from 'react'
import { Home } from './pages/Home'
import Arrivals from './pages/Arrivals'
import Departures from './pages/Departures'
import Blog from './pages/Blog'
import Test from './pages/Test'
import VekslePenge from './pages/blogposts/VekslePenge'
import RejseMedPenge from './pages/blogposts/RejseMedPenge'
import VaeskeMaengderSecurity from './pages/blogposts/VaeskeMaengderSecurity'
import BilligeRejsedestinationer from './pages/blogposts/BilligeRejsedestinationer'
import HvadKosterEtPas from './pages/blogposts/HvadKosterEtPas'
import HvorHurtigtFlyverEtFly from './pages/blogposts/HvorHurtigtFlyverEtFly'
import OpladerHaandbagage from './pages/blogposts/OpladerHaandbagage'
import PilotLoen from './pages/blogposts/PilotLoen'
import PasMedTilSverige from './pages/blogposts/PasMedTilSverige'
import FlyvelederLoen from './pages/blogposts/FlyvelederLoen'
import Stewardesse from './pages/blogposts/Stewardesse'
import Jetlag from './pages/blogposts/Jetlag'

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/test',
    element: <Test />
  },
  {
    path: '/ankomst-cph',
    element: <Arrivals />
  },
  {
    path: '/afgange-cph',
    element: <Departures />
  },
  {
    path: '/blog',
    element: <Blog />
  },
  {
    path: '/blog/hvor-mange-kontanter-maa-man-have-paa-sig',
    element: <RejseMedPenge />
  },
  {
    path: '/blog/veksle-penge-i-lufthavnen',
    element: <VekslePenge />
  },
  {
    path: '/blog/hvor-mange-ml-maa-man-have-med-i-haandbagage',
    element: <VaeskeMaengderSecurity />
  },
  {
    path: '/blog/billige-rejsedestinationer',
    element: <BilligeRejsedestinationer />
  },
  {
    path: '/blog/hvad-koster-et-pas',
    element: <HvadKosterEtPas />
  },
  {
    path: '/blog/hvor-hurtigt-flyver-et-fly',
    element: <HvorHurtigtFlyverEtFly />
  },
  {
    path: '/blog/oplader-i-haandbagage',
    element: <OpladerHaandbagage />
  },
  {
    path: '/blog/pilot-loen',
    element: <PilotLoen />
  },
  {
    path: '/blog/pas-med-til-sverige',
    element: <PasMedTilSverige />
  },
  {
    path: '/blog/flyveleder-loen',
    element: <FlyvelederLoen />
  },
  {
    path: '/blog/stewardesse',
    element: <Stewardesse />
  },
  {
    path: '/blog/jetlag',
    element: <Jetlag />
  }
]

export default AppRoutes
