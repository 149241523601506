import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  blogTitle: string
  url: string
  image: string
  imageAlt: string
  description: string
}

export default function BlogCard({ blogTitle, url, image, imageAlt, description }: Props) {
  return (
    <div className='card'>
      <a href={`/blog/${url}`}>
        <img className='card-img-top' src={`img/${image}`} alt={imageAlt} height={286} width={180} style={{objectFit:'cover'}} />
      </a>

      <div className='card-body'>
        <h5 className='card-title'>{blogTitle}</h5>

        <p className='card-text' style={{ color: 'black' }}>
          {description}
        </p>
        
        <a href={`/blog/${url}`} className='btn btn-primary'>
          Læs mere
        </a>
      </div>
    </div>
  )
}
