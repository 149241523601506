import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'

export default function Jetlag() {
  useEffect(() => {
    let title = 'Hvad er Jetlag? Symptomer og Tips'
    let metaDescription = 'Lær om jetlag, dets symptomer, og få tips til, hvordan du bedst håndterer det på rejser fra USA til Danmark.'
    let canonical = 'https://cphsecurityventetid.dk/blog/jetlag'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/jetlag#hvad-er-jetlag', text: 'Hvad er Jetlag?' },
    { url: 'blog/jetlag#symptomer', text: 'Symptomer på Jetlag' },
    { url: 'blog/jetlag#usa-dk', text: 'Jetlag fra USA til Danmark' },
    { url: 'blog/jetlag#undga-jetlag', text: 'Hvordan undgår man Jetlag?' },
    { url: 'blog/jetlag#bog-anbefalinger', text: 'Boganbefalinger om Jetlag' },
    { url: 'blog/jetlag#konklusion', text: 'Konklusion' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Hvad er Jetlag? Symptomer og Tips</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>14. april 2024</i>
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/jetlag-1.webp' alt='Mand med jetlag i lufthavnen' />

            <h2 id='hvad-er-jetlag'>Hvad er Jetlag?</h2>
            <p>
              Jetlag, også kendt som desynkronose, er en midlertidig søvnforstyrrelse, der opstår, når din krops indre ur eller døgnrytme
              ikke er synkroniseret med det nye tidszone. Dette sker typisk efter flyrejser over flere tidszoner. Din krops circadiane
              rytme, der styrer fysiologiske processer som søvn, vågenhed og metabolisme, bliver forstyrret, hvilket resulterer i flere
              symptomer.
            </p>

            <h2 id='symptomer'>Symptomer på Jetlag</h2>
            <p>Symptomer på jetlag kan variere betydeligt fra person til person, men de mest almindelige inkluderer:</p>
            <ul>
              <li>
                <p>
                  <strong>Træthed på uventede tidspunkter:</strong> Du kan føle dig usædvanligt træt midt på dagen eller tidligt om aftenen,
                  hvilket er en direkte følge af din krops interne ur, der stadig opererer i en anden tidszone.
                </p>
              </li>
              <li>
                <p>
                  <strong>Svært ved at falde i søvn om natten:</strong> Selvom du føler dig træt i løbet af dagen, kan det være udfordrende
                  at falde i søvn, når det rent faktisk er sengetid i den nye tidszone.
                </p>
              </li>
              <li>
                <p>
                  <strong>Vågne op for tidligt:</strong> Jetlag kan forårsage, at du vågner flere timer tidligere end normalt, hvilket ofte
                  resulterer i, at du ikke får nok søvn.
                </p>
              </li>
              <li>
                <p>
                  <strong>Koncentrationsbesvær:</strong> Manglen på ordentlig hvile kan nedsætte din evne til at fokusere og opretholde
                  opmærksomhed, hvilket kan påvirke både arbejde og personlige aktiviteter.
                </p>
              </li>
              <li>
                <p>
                  <strong>Humørsvingninger:</strong> Uregelmæssigheder i søvn og træthed kan føre til irritation eller følelsesmæssig
                  ubalance, hvilket gør dig mere sårbar over for stemningsskift.
                </p>
              </li>
              <li>
                <p>
                  <strong>Fordøjelsesbesvær:</strong> Tidszoneændringer kan også påvirke din krops metabolisme, hvilket kan resultere i
                  fordøjelsesproblemer som forstoppelse eller diarré.
                </p>
              </li>
            </ul>

            <h2 id='usa-dk'>Jetlag fra USA til Danmark</h2>
            <p>
              Rejser fra USA til Danmark indebærer typisk at krydse flere tidszoner, hvilket kan intensivere symptomerne på jetlag. Hvis du
              rejser fra østkysten, som New York, til Danmark, vil du krydse seks tidszoner, og fra vestkysten som Californien, vil du
              krydse ni tidszoner. Jo flere zoner du krydser, desto mere sandsynligt er det, at du vil opleve udtalte symptomer på jetlag.
            </p>
            <p>
              Når du rejser østpå, mod Danmark, modarbejder du uret ved at "tabe" tid. Dette betyder, at din krop skal justere sig hurtigere
              end den naturlige rytme typisk tillader. For eksempel, når det er midnat i Danmark, er det kun mid-eftermiddag i Californien.
              Denne tidlige mørklægning og det faktum, at det er tidligt på dagen i din oprindelige tidszone, kan gøre det vanskeligt at
              falde i søvn og forblive sovende.
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/jetlag-2.webp' alt='Jetlag fra USA til Danmark' />

            <p>
              Denne tilpasning kan være særlig udfordrende på grund af den betydelige "forskydning" i dag- og nattetider. For at mildne
              effekterne af jetlag anbefales det at tilpasse din søvnrytme et par dage før afrejsen, hvor du gradvist justerer dine sove- og
              vågnetider nærmere til den tidszone, du vil befinde dig i. Det kan også hjælpe at vælge flyafgange, der letter en naturlig
              tilpasning til tidszonen i Danmark. Vi giver dig flere tips i næste afsnit.
            </p>

            <h2 id='undga-jetlag'>Hvordan Undgår Man Jetlag?</h2>
            <p>
              Forebyggelse af jetlag kan involvere en række strategier, herunder gradvis tilpasning til den nye tidszone før afrejse, at
              holde sig hydreret, og bruge lys til at genkalibrere din døgnrytme ved ankomst.
            </p>
            <p>Her er nogle strategier for at minimere jetlag:</p>
            <ol>
              <li>
                <p>
                  <b>Tilpas dig gradvist til den nye tidszone:</b> Start med at justere din søvnplan et par dage før din rejse. Hvis du
                  rejser østpå, prøv at gå i seng og stå op tidligere.
                </p>
              </li>
              <li>
                <p>
                  <b>Hold dig hydreret:</b> Undgå alkohol og koffein under din flyvning, da de kan forværre dehydrering og forstyrre din
                  søvn.
                </p>
              </li>
              <li>
                <p>
                  <b>Brug naturligt lys til at justere din døgnrytme:</b> Eksponering for naturligt lys vil hjælpe med at justere din krops
                  interne ur. Prøv at få så meget dagslys som muligt, når du ankommer til din destination.
                </p>
              </li>
              <li>
                <p>
                  <b>Overvej kortvarig brug af melatonin:</b> Melatonin kan hjælpe med at regulere din døgnrytme. Tal med din læge, før du
                  tager melatonin, især hvis du tager andre medicin.
                </p>
              </li>
              <li>
                <p>
                  <b>Vær aktiv under din rejse:</b> Let motion kan også hjælpe med at justere din krop til en ny tidszone.
                </p>
              </li>
            </ol>

            <div className='text-center'>
              <iframe
                width='315'
                height='560'
                src='https://www.youtube.com/embed/EWOcZPCGPf4?si=U_ahfdUp6dBBshnw'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              ></iframe>
            </div>

            <h2 id='bog-anbefalinger'>Boganbefalinger om Jetlag</h2>
            <p>
              For dem, der søger dybere indsigter og strategier til at bekæmpe jetlag, kan visse rejsebøger være en stor hjælp. Titler som
              "The Jetlag Plan" af Charles Ehret og Lynne Waller Scanlon, og "Overcoming Jet Lag" af Dr. Charles F. Ehret og Lynne Waller
              Scanlon, tilbyder både forskningsbaserede strategier og praktiske tips, der er lette at implementere.
            </p>

            <h2 id='konklusion'>Konklusion</h2>
            <p>
              Jetlag er en uundgåelig del af langdistanceflyvninger, men ved at forstå, hvad det er, anerkende symptomerne, og anvende
              effektive strategier, kan du betydeligt reducere dets indvirkning på dit liv. Med planlægning, forberedelse og de rette
              værktøjer kan du hurtigere tilpasse dig nye tidszoner og gøre din rejseoplevelse mere behagelig. For yderligere vejledning,
              overvej at dykke ned i nogle af de anbefalede jetlag travel books for at gøre din næste transatlantiske rejse til en mere
              behagelig oplevelse.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
