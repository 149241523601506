import React, { Component } from 'react'
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav } from 'reactstrap'
import { Link } from 'react-router-dom'

interface Props {
  collapsed: boolean
}

export class NavMenu extends Component<{}, Props> {
  static displayName = NavMenu.name

  constructor(props: {}) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.closeNavbar = this.closeNavbar.bind(this)
    this.state = {
      collapsed: true
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  closeNavbar() {
    this.setState({
      collapsed: true
    })
  }

  render() {
    return (
        <Navbar className='navbar navbar-expand-lg navbar-light bg-white py-3 mb-5' container light>
          <NavbarBrand tag={Link} to='/' className='fw-bolder text-cph' onClick={this.closeNavbar}>
            <img height={20} width={20} src='/favicon.png' style={{ marginRight: 5, marginBottom: 5 }} alt="CphSecurityVentetid Logo"></img> CphSecurityVentetid.dk
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className='mr-2'/>
          <Collapse className='d-sm-inline-flex flex-sm-row-reverse' isOpen={!this.state.collapsed} navbar>
            <Nav className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
              <NavItem>
                <NavLink tag={Link} className='nav-link' to='/afgange-cph' onClick={this.closeNavbar}>
                  Afgange
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className='nav-link' to='/ankomst-cph' onClick={this.closeNavbar}>
                  Ankomster
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className='nav-link' to='/blog' onClick={this.closeNavbar}>
                  Blog
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
    )
  }
}
