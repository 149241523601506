import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'
import { Link } from 'react-router-dom'

export default function VaeskeMaengderSecurity() {
  useEffect(() => {
    let title = 'Hvor mange ml må man have med i håndbagagen?'
    let metaDescription =
      'Forstå reglerne for håndbagage i Danmark: lær om ml-begrænsninger og tips til at pakke flydende stoffer korrekt for en sikker og smidig flyrejse.'
    let canonical = 'https://cphsecurityventetid.dk/blog/hvor-mange-ml-maa-man-have-med-i-haandbagage'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#grundlaeggende', text: 'Det grundlæggende: Mængder og begrænsninger' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#hvorfor', text: 'Hvorfor er der grænser for væsker i håndbagage?' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#pakning', text: 'Sådan pakker du væsker korrekt i din håndbagage' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#undtagelser', text: 'Undtagelser og særlige omstændigheder' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#faq', text: 'Ofte stillede spørgsmål og misforståelser' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#tips', text: 'Tips til sikkerhedskontrol med håndbagage' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#konsekvenser', text: 'Konsekvenser af overtrædelse af reglerne' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#sammenligning', text: 'Sammenligning med andre landes regler' },
    { url: 'blog/hvor-mange-ml-maa-man-have-med-i-haandbagage#afslutning', text: 'Afslutning' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Hvor mange ml må man have med i håndbagagen?</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>26. januar 2024</i>
            </p>

            <p>
              Når man rejser med fly, er der mange regler, man skal være opmærksom på, og en af de vigtigste regler vedrører mængden af
              væsker i håndbagagen. I denne artikel vil vi dykke ned i, præcis hvor mange ml man må have med i håndbagage på flyrejser i
              Danmark, samt forklare baggrunden og vigtigheden af disse regler.
            </p>

            <img
              className='img-fluid img-blog-square rounded mx-auto d-block'
              src='img/vaesker-i-security-1.jpg'
              alt='For mange væsker i security i CPH lufthavn'
            />

            <h2 id='grundlaeggende'>Det grundlæggende: Mængder og begrænsninger</h2>
            <p>
              Reglerne for væsker/flydende stoffer i håndbagagen er ret ensartede verden over, takket være internationale
              sikkerhedsstandarder. I Danmark følger vi disse internationale retningslinjer, som siger, at du må medbringe flydende stoffer
              i din håndbagage, så længe hver beholder ikke overstiger 100 ml. Det samlede antal af disse beholdere skal kunne passe ind i
              en gennemsigtig, genlukkelig plastikpose, der ikke må være større end 1 liter i kapacitet.
            </p>

            <h2 id='hvorfor'>Hvorfor er der grænser for væsker i håndbagage?</h2>
            <p>
              Sikkerhedsreglerne for flydende stoffer i håndbagage blev indført som en direkte reaktion på specifikke sikkerhedstrusler og
              er designet til at forhindre ulovlige stoffer, såsom eksplosiver, i at blive bragt om bord på fly. Disse regler er ikke kun en
              formalitet; de spiller en afgørende rolle i at opretholde sikkerheden for alle om bord.
            </p>

            <h2 id='pakning'>Sådan pakker du væsker korrekt i din håndbagage</h2>
            <p>
              For at sikre en problemfri gennemgang af sikkerhedskontrollen, er det vigtigt at pakke dine flydende stoffer korrekt. Alle
              flydende stoffer, inklusive geler, pastaer, cremer, og lignende konsistenser, skal opbevares i beholdere på maksimalt 100 ml
              og skal være anbragt i en gennemsigtig, genlukkelig plastikpose. Det er vigtigt at huske, at kun én sådan pose er tilladt per
              passager.
            </p>

            <h2 id='undtagelser'>Undtagelser og særlige omstændigheder</h2>
            <p>
              Der er visse undtagelser til reglen om 100 ml. Medicinske væsker og babyføde er ofte undtaget, men det er vigtigt at medbringe
              dokumentation, såsom en lægeerklæring, og informere sikkerhedspersonalet om disse undtagelser. Disse undtagelser sikrer, at
              passagerer med særlige behov stadig kan rejse med komfort og sikkerhed.
            </p>

            <h2 id='faq'>Ofte stillede spørgsmål og misforståelser</h2>
            <p>
              En almindelig misforståelse er, at det kun er mængden af væske i hver beholder, der tæller, og ikke beholderens faktiske
              størrelse. Det er vigtigt at understrege, at ingen beholdere må overstige 100 ml kapacitet, selv hvis de ikke er fulde. Dette
              er en standard, der strengt håndhæves i sikkerhedskontrollen. Der er også mange der er i tvivl om man må have{' '}
              <a href='/blog/oplader-i-haandbagage'>oplader med i håndbagagen</a>.
            </p>

            <h2 id='tips'>Tips til sikkerhedskontrol med håndbagage</h2>
            <p>
              For at sikre en hurtig og nem proces gennem sikkerhedskontrollen, anbefales det at pakke dine flydende stoffer i den tilladte
              plastikpose på forhånd og placere den øverst i din håndbagage for let adgang. Husk også at fjerne alle større elektroniske
              enheder, som laptops, fra din taske inden screening.
            </p>

            <img
              className='img-fluid img-blog-square rounded mx-auto d-block'
              src='img/vaesker-i-security-2.jpg'
              alt='Godkendt væsker i security i CPH lufthavn'
            />

            <h2 id='konsekvenser'>Konsekvenser af overtrædelse af reglerne</h2>
            <p>
              Overtrædelse af disse regler kan resultere i beslaglæggelse af de forbudte genstande, og i værste fald kan det medføre
              yderligere forsinkelser eller endda forhindre dig i at boarde dit fly. Det er derfor afgørende at overholde reglerne for en
              problemfri rejse.
            </p>

            <h2 id='sammenligning'>Sammenligning med andre landes regler</h2>
            <p>
              Selvom reglerne er ret ensartede globalt, kan der være små forskelle fra land til land. Når du rejser internationalt, er det
              derfor vigtigt at tjekke de specifikke regler for både afrejse- og ankomstlandet for at undgå uventede problemer.
            </p>

            <h2 id='afslutning'>Afslutning</h2>
            <p>
              At forstå og overholde reglerne omkring, hvor mange ml man må have med i håndbagage, er essentielt for en sikker og behagelig
              flyrejse. Husk på de grundlæggende begrænsninger af 100 ml per beholder og den gennemsigtige plastikpose på 1 liter, samt at
              forberede dig på eventuelle særlige omstændigheder. Ved at følge disse retningslinjer sikrer du ikke kun din egen rejsekomfort
              men bidrager også til sikkerheden for alle om bord på dit fly.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
