import React from 'react'
import SidebarAd from './ads/SidebarAd'

interface SidebarLink {
  url: string
  text: string
}

interface SidebarLinksProps {
  links: SidebarLink[]
}

export default function BlogSidebar({ links }: SidebarLinksProps) {
  return (
    <div className='col-lg-4 order-lg-2 order-1'>
      <div className='sticky-top' style={{ top: '75px' }}>
        <h2>Indholdsfortegnelse</h2>
        <ul className='table-of-contents mb-5'>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.url}>{link.text}</a>
            </li>
          ))}
        </ul>
        <SidebarAd/>
      </div>
    </div>
  )
}
