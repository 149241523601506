import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'

export default function PilotLoen() {
  useEffect(() => {
    let title = 'Pilot løn'
    let metaDescription =
      'Udforsk indkomstpotentialet og karriereudviklingen for piloter i Danmark, fra begynderlønninger til kaptajnens imponerende lønpakker, og hvordan senioritet påvirker jobmobilitet.'
    let canonical = 'https://cphsecurityventetid.dk/blog/pilot-loen'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/pilot-loen#hvad-tjener-en-pilot', text: 'Hvad tjener en pilot?' },
    { url: 'blog/pilot-loen#hvordan-bliver-man-pilot', text: 'Hvordan bliver man pilot?' },
    { url: 'blog/pilot-loen#hvor-lang-tid', text: 'Hvor lang tid tager det at blive pilot?' },
    { url: 'blog/pilot-loen#senioritet', text: 'Pilotsenioritet' },
    { url: 'blog/pilot-loen#jobmobilitet', text: 'Gyldne håndjern og jobmobilitet' },
    { url: 'blog/pilot-loen#kaptajn', text: 'Sådan bliver man kaptajn' },
    { url: 'blog/pilot-loen#konklusion', text: 'Konklusion' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Pilot løn</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>23. marts 2024</i>
            </p>

            <p>
              At navigere gennem en karriere som pilot i Danmark er en rejse fyldt med både udfordringer og belønninger. Fra de første
              skridt i flyveuddannelsen til at indtage kaptajnens sæde, afhænger en pilots løn af en række faktorer, herunder erfaring,
              flyselskabets størrelse, og ikke mindst senioritet. Denne artikel udforsker, hvad en pilot kan forvente at tjene gennem deres
              karriere, og de unikke aspekter af luftfartsindustrien, der påvirker løn og jobmobilitet.
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/pilot-loen-1.webp' alt='To pilot i et cockpit' />

            <h2 id='hvad-tjener-en-pilot'>Hvad tjener en pilot?</h2>
            <p>
              Piloters lønninger i Danmark spænder bredt, med begynderlønninger for nyuddannede piloter ofte liggende mellem 30.000-40.000
              DKK om måneden. Efterhånden som erfaring og kompetencer vokser, kan en erfaren styrmand forvente en løn på den højere ende af
              dette spektrum. Så hvor meget en pilot tjener afhænger især af anciennitet. Overgangen fra styrmand til kaptajn bringer ikke
              kun et skift i ansvar, men også en betydelig lønstigning, typisk omkring 30-40% mere end en erfaren styrmands løn.
            </p>

            <p>
              For en kaptajn ved et større dansk eller internationalt flyselskab kan lønnen plus tillæg for ugunstige arbejdstider og
              overarbejde let overstige 100.000 DKK om måneden, hvilket understreger den økonomiske værdi af at avancere i karrieren inden
              for luftfartsbranchen.
            </p>

            <h2 id='hvordan-bliver-man-pilot'>Hvordan bliver man pilot?</h2>
            <p>
              For at sætte kurs mod en karriere som pilot, starter man typisk med en grundig selektionsproces hos en flyveskole, som kan
              omfatte både fysiske prøver og psykometriske tests. Efter at have sikret sig en plads, gennemgår aspirerende piloter en
              intensiv uddannelsesperiode, der omfatter teori, flyvetræning og simulatorøvelser. Det er en investering i både tid og penge,
              men for mange er belønningen – at flyve kommercielt og opleve verden fra cockpit – uvurderlig.
            </p>

            <h2 id='hvor-lang-tid'>Hvor lang tid tager det at blive pilot?</h2>
            <p>
              At blive pilot er en tidskrævende proces, der kræver dedikation og en betydelig investering. Uddannelsen til pilot, fra start
              til CPL (Commercial Pilot License), tager typisk mellem 18 til 24 måneder. Men det stopper ikke der; at opnå de nødvendige
              flyvetimer og yderligere certificeringer, som f.eks. en ATPL (Airline Transport Pilot License), der kræves for at blive
              kaptajn, kan tage flere år.
            </p>

            <h2 id='senioritet'>Pilotsenioritet</h2>
            <p>
              Et centralt aspekt af karriereudvikling for piloter er senioritetsprincippet. Dette system favoriserer anciennitet i selskabet
              og spiller en kritisk rolle, når det kommer til forfremmelser til kaptajn. Men det bærer også med sig en risiko: hvis en
              kaptajn mister sit job på grund af f.eks. konkurs, kan vedkommende stå over for at skulle starte forfra som styrmand i et nyt
              selskab, ofte på den laveste løntrin.
            </p>
            <p>
              I sjældne tilfælde kan en erfaren pilot opnå en "direct entry command" i et nyt selskab, hvilket betyder at starte direkte som
              kaptajn, typisk under omstændigheder, hvor der er mangel på kvalificerede kaptajner.
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/pilot-loen-2.webp' alt='Lufthavn med fly og solnedgang' />

            <h2 id='jobmobilitet'>Gyldne håndjern og jobmobilitet</h2>
            <p>
              Begrebet "gyldne håndjern" refererer til de situationer, hvor piloter, på grund af favorable ansættelsesforhold og senioritet,
              finder det vanskeligt at skifte flyselskab. Denne tilknytning til et selskab kan blive så stærk, at piloter foretrækker at
              pendle lange afstande frem for at miste deres oparbejdede status. Dette understøttes yderligere af "last in, first
              out"-princippet, som beskytter de mere erfarne piloters job i tilfælde af nedskæringer.
            </p>

            <h2 id='kaptajn'>Sådan bliver man kaptajn</h2>
            <p>
              At nå kaptajnens sæde i et fly er toppen af karrierestigen for mange piloter. Denne position repræsenterer ikke blot en
              betydelig erhvervsmæssig bedrift, men også en anerkendelse af en pilots ekspertise, erfaring og lederevner. Processen for at
              blive kaptajn er dog både udfordrende og krævende, med flere trin og kriterier, der skal opfyldes undervejs.
            </p>

            <p>For at sætte kurs mod kaptajnens sæde starter rejsen med en grundig pilotuddannelse. Dette indebærer typisk:</p>
            <ul>
              <li>
                <p>
                  <strong>PPL (Private Pilot License):</strong> Dit første skridt inden for flyvning, hvor du lærer de grundlæggende
                  færdigheder.
                </p>
              </li>
              <li>
                <p>
                  <strong>ATPL-teori (Airline Transport Pilot License):</strong> En omfattende teoretisk uddannelse, der dækker alt fra
                  flyvningens principper til avanceret navigation.
                </p>
              </li>
              <li>
                <p>
                  <strong>CPL (Commercial Pilot License):</strong> Dette trin giver dig mulighed for at flyve kommercielt og tjene penge som
                  pilot.
                </p>
              </li>
              <li>
                <p>
                  <strong>Type Rating:</strong> Specialiseret træning for det specifikke fly, du ønsker at flyve.
                </p>
              </li>
            </ul>

            <h2 id='konklusion'>Konklusion</h2>
            <p>
              Piloters lønninger i Danmark afspejler ikke kun deres tekniske færdigheder og ansvar, men også kompleksiteten i
              luftfartsindustriens karrierestrukturer. Fra de første skridt som nyuddannet pilot til de udfordringer og belønninger, der
              følger med kaptajnens rolle, er karrieren som pilot både økonomisk givende og dybt tilfredsstillende for dem med en passion
              for at flyve.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
