import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import FlightTable from '../components/FlightTable'
import { FlightData } from '../types'
import { updateDocumentHead } from '../utility'
import ArrivalsAd from '../components/ads/ArrivalsAd'

export default function Arrivals() {
  let title = 'Ankomster Københavns Lufthavn (CPH) - CphSecurityVentetid'
  let metaDescription =
    'Oversigt over ankomster i CPH Lufthavn samt opdaterede ankomsttider og information om ankomsthallen i Københavns Lufthavn. Følg ankomsten på dit fly helt nøje.'
  let canonical = 'https://cphsecurityventetid.dk/ankomst-cph'

  updateDocumentHead(title, metaDescription, canonical)

  const [flightData, setFlightData] = useState<FlightData[]>([])

  useEffect(() => {
    // Use a fetch or axios call to fetch the JSON data from your source
    fetch('api/journey/arrivals')
      .then((response) => response.json())
      .then((data) => {
        setFlightData(data)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, []) // Empty dependency array to ensure the effect runs only once

  const doSearchDebounced = useCallback(
    debounce(
      (searchText) => {
        let baseUrl = 'api/journey/arrivals'
        let callingUrl = baseUrl
        if (searchText.length > 1) {
          callingUrl = baseUrl + '?searchText=' + searchText.toLowerCase()
        }
        fetch(callingUrl)
          .then((response) => response.json())
          .then((data) => setFlightData(data))
          .catch((error) => console.error('Error fetching data:', error))
      },
      500,
      { trailing: true }
    ),
    []
  )

  const handleSearch = (e: { target: { value: string | any[] } }) => {
    if (e.target.value.length > 1) {
      doSearchDebounced(e.target.value)
    } else {
      doSearchDebounced('')
    }
  }

  return (
    <>
      <h1>Ankomster Københavns Lufthavn (CPH)</h1>
      <p>
        Velkommen til vores opdaterede oversigt over ankomster til Københavns Lufthavn (CPH). Her kan du finde oplysninger om flyankomster,
        tider og oprindelsessteder for de seneste flyvninger, der landede i København. Københavns Lufthavn er en travl international
        lufthavn med forbindelser fra hele verden. Vi giver dig de seneste oplysninger om ankomster for at gøre din rejseplanlægning
        lettere.
      </p>

      <h2>Aktuelle Ankomster (CPH)</h2>
      <p className='mb-4'>
        Nedenfor finder du en liste over de næste 12 timers ankomster til Københavns Lufthavn. Se oplysninger om oprindelsessted, flyselskab
        og aktuelle tider.
      </p>

      <ArrivalsAd />
      <div className='mb-4'></div>

      <FlightTable flightData={flightData} direction={'Fra'} handleSearch={handleSearch} />

      <p>
        Københavns Lufthavn, også kendt som CPH, er Skandinaviens største og mest travle lufthavn. Hvert år byder den tusindvis af rejsende
        velkommen fra nær og fjern, der ankommer til den danske hovedstad. Ankomsthallen er ofte det første sted, hvor besøgende får et
        indblik i det charmerende danske gæstfrihed og effektive service. Herunder tager vi dig igennem ankomster i CPH og viser dig,
        hvordan du nemt kommer til ankomsthallen.
      </p>

      <h2>Ankomsthallen</h2>

      <p>
        Ankomsthallen i Københavns Lufthavn er det hjerte, hvor alle ankomster mødes. Det er her, du vil finde glæde i genforening med
        familie og venner eller begynde dit eventyr i Danmark. Ankomsthallen er veludstyret med faciliteter og tjenester, der gør ankomsten
        til en glat oplevelse.
      </p>

      <ul>
        <li>
          <strong>Bagageudlevering:</strong> Efter din flyvning vil du blive ledt til bagageudleveringen i ankomsthallen. Her kan du afhente
          din bagage, og skiltning gør det let at finde din flyvning og den rigtige bånd.
        </li>
        <li>
          <strong>Toldkontrol:</strong> Inden du forlader ankomsthallen, skal du gå igennem toldkontrollen. Danmark har strenge toldregler,
          så sørg for at følge anvisningerne og deklarer eventuelle varer, du har med dig.
        </li>
        <li>
          <strong>Transport:</strong> Når du forlader ankomsthallen, har du flere transportmuligheder til din rådighed. Du kan vælge mellem
          taxaer, offentlig transport eller leje en bil i lufthavnen, afhængig af dine præferencer og destination.
        </li>
      </ul>

      <h2>Hvordan man kommer til ankomsthallen</h2>

      <p>
        At komme til ankomsthallen i Københavns Lufthavn er en simpel og bekvem proces, uanset om du ankommer fra en international
        destination eller en indenrigsrejse.
      </p>

      <h3>Fra internationale ankomster</h3>
      <p>
        Hvis du ankommer fra en international destination, vil du følge skiltningen til "Ankomst" efter at have gennemført grænsekontrollen
        og tolden. Ankomsthallen er normalt placeret i den samme terminal som din ankomstgate. Du kan nemt finde informationsskranke og
        transportmuligheder, der fører dig videre til din endelige destination.
      </p>

      <h3>Fra indenrigsankomster</h3>
      <p>
        Indenrigsankomster i Københavns Lufthavn er ofte mere ligetil. Efter landing vil du følge skiltningen til "Ankomst" og
        bagageudleveringen. Ankomsthallen er normalt tilgængelig med en kort gåtur fra dit ankomstområde.
      </p>

      <h3>Transportmuligheder</h3>
      <p>
        Når du er i ankomsthallen, finder du transportmuligheder som taxaer, busser og tog. Lufthavnen er forbundet til København by og
        omegn med et veludviklet transportsystem, så det er nemt at komme videre til din destination. Taxaholdepladser og
        biludlejningsskranke er tilgængelige lige uden for ankomsthallen.
      </p>

      <p>
        Københavns Lufthavn lægger stor vægt på at gøre ankomsterne til en problemfri oplevelse, og du vil finde en hjælpsom
        lufthavnspersonale klar til at besvare dine spørgsmål og bistå dig med dine behov.
      </p>

      <p>
        Så velkommen til København! Uanset om du er en besøgende, der udforsker Danmark for første gang eller en lokal, der vender hjem,
        håber vi, at din ankomst i Københavns Lufthavn bliver starten på en mindeværdig rejse.
      </p>
    </>
  )
}
