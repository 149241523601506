import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'

export default function OpladerHaandbagage() {
  useEffect(() => {
    let title = 'Må man have oplader med i håndbagage?'
    let metaDescription =
      'Find ud af, om du må have oplader med i håndbagagen på din næste flyrejse. Læs vores guide til regler for elektroniske enheder og opladere i lufthavne.'
    let canonical = 'https://cphsecurityventetid.dk/blog/oplader-i-haandbagage'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/oplader-i-haandbagage#regler', text: 'Sikkerhedsforanstaltninger og regler' },
    { url: 'blog/oplader-i-haandbagage#begraensninger', text: 'Batterityper og begrænsninger' },
    { url: 'blog/oplader-i-haandbagage#tips', text: 'Praktiske tips til rejsende' },
    { url: 'blog/oplader-i-haandbagage#afsluttende-tanker', text: 'Afsluttende tanker' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Må man have oplader med i håndbagage?</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>23. marts 2024</i>
            </p>

            <p>
              I takt med at vores afhængighed af elektroniske gadgets vokser, stiller mange sig spørgsmålet: "Er det tilladt at medbringe
              opladere i håndbagagen?" For at besvare dette kræver det en dybdegående forståelse af de gældende regler og retningslinjer for
              transport af opladere ved flyrejser, med særligt fokus på Københavns Lufthavn, som er et centralt hub for rejsende fra og til
              Danmark.
            </p>

            <img
              className='img-fluid img-blog rounded mx-auto d-block'
              src='img/oplader-i-haandbagage-1.webp'
              alt='Mange opladere i en håndbagage'
            />

            <h2 id='regler'>Sikkerhedsforanstaltninger og regler</h2>
            <p>
              Når du pakker din håndbagage, er det vigtigt at være opmærksom på de sikkerhedsforanstaltninger, som lufthavnene og
              flyselskaberne har sat. Generelt er det tilladt at medbringe opladere i håndbagagen. Dette gælder både for opladere til
              mobiltelefoner, bærbare computere, tablets og andre små elektroniske enheder.
            </p>
            <p>
              Det er dog vigtigt at bemærke, at alle elektroniske enheder og deres tilbehør skal kunne tåle sikkerhedskontrollens
              røntgenundersøgelse. Sørg for at pakke dine opladere på en sådan måde, at de let kan tages ud af din håndbagage, hvis
              sikkerhedspersonalet beder om det.
            </p>

            <h2 id='begraensninger'>Batterityper og begrænsninger</h2>
            <p>
              Mens almindelige opladere generelt er tilladt, er der særlige regler for powerbanks og eksterne batterier, som er vigtige at
              kende til. Disse enheder betragtes som lithiumbatterier og er underlagt visse begrænsninger på grund af deres brandrisiko.
            </p>
            <p>
              Reglerne dikterer ofte, at powerbanks skal bæres i håndbagagen og ikke i indtjekket bagage. Derudover er der ofte en grænse
              for kapaciteten af lithiumbatterier, målt i Watt-timer (Wh), som typisk skal være under 100 Wh. Det er altid en god idé at
              tjekke de specifikke regler hos dit flyselskab og Københavns Lufthavn før afrejse.
            </p>

            <h2 id='tips'>Praktiske tips til rejsende</h2>
            <p>For at gøre din rejse så problemfri som muligt, her er nogle praktiske tips:</p>

            <ul>
              <li>
                <p>
                  <b>Organisering:</b> Pak dine opladere og kabler i en lille taske eller en kabelorganisator. Dette holder din håndbagage
                  organiseret og gør det nemmere for sikkerhedskontrollen.
                </p>
              </li>
              <li>
                <p>
                  <b>Tjek kapaciteten:</b> For powerbanks, tjek altid kapaciteten og sørg for, at den overholder flyselskabets regler.
                </p>
              </li>
              <li>
                <p>
                  <b>Forbered til sikkerhedskontrol:</b> Vær forberedt på at fjerne dine opladere fra din håndbagage under
                  sikkerhedskontrollen, især hvis de er pakket i tætte bundter eller indeholder store batterier.
                </p>
              </li>
            </ul>

            <img
              className='img-fluid img-blog rounded mx-auto d-block'
              src='img/oplader-i-haandbagage-2.webp'
              alt='Venter på fly med opladere i en håndbagage'
            />

            <h2 id='afsluttende-tanker'>Afsluttende tanker</h2>
            <p>
              Så, for at svare på det oprindelige spørgsmål: Ja, du må gerne have en oplader med i din håndbagage, når du flyver. Det er dog
              vigtigt at være opmærksom på de regler og begrænsninger, der gælder for specifikke typer af batterier og opladere. Ved at
              følge disse enkle retningslinjer kan du sikre, at din rejse gennem Københavns Lufthavn bliver så glidende som muligt, og at du
              altid har strøm til dine enheder, uanset hvor i verden du befinder dig.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
