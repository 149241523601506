import React from 'react'
import { FlightData } from '../types'

interface Props {
  direction: string
  handleSearch: (e: { target: { value: string | any[] } }) => void
  flightData: FlightData[]
}

export default function FlightTable({ flightData, direction, handleSearch }: Props) {
  return (
    <>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <h3>{getCurrentDate()}</h3>
        </div>
        <div className='col-sm-12 col-md-6 search-bar'>
          <input type='text' className='form-control' placeholder='Søg efter fly' aria-label='Søg efter fly' onChange={handleSearch} />
        </div>
      </div>

      <div className='table-responsive' style={{ marginTop: '1rem' }}>
        <table className='table table-dark table-striped table-bordered table-hover'>
          <thead className='thead-dark'>
            <tr>
              <th>Tid</th>
              <th>{direction}</th>
              <th>Flyselskab</th>
              <th>Fly nr.</th>
              <th>Gate</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {flightData.map((flight, index) => (
              <tr key={`${flight.flightNumber}-${flight.destinationName}-${index}`}>
                <td>
                  {flight.time === flight.expectedTime ? (
                    flight.time
                  ) : (
                    <>
                      <s>{flight.time}</s> {flight.expectedTime}
                    </>
                  )}
                </td>
                <td>{flight.destinationName}</td>
                <td>{flight.airline}</td>
                <td>{flight.flightNumber}</td>
                <td>{flight.gate}</td>
                <td>{flight.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

function getCurrentDate() {
  const currentDate = new Date()
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  return currentDate.toLocaleString('da-DK', options)
}