import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import FlightTable from '../components/FlightTable'
import { FlightData } from '../types'
import { updateDocumentHead } from '../utility'
import DeparturesAd from '../components/ads/DeparturesAd'

export default function Departures() {
  let title = 'Afgange Københavns Lufthavn (CPH) - CphSecurityVentetid'
  let metaDescription =
    'Planlæg din rejse smidigt med opdaterede data om afgange fra CPH Lufthavn. Få information om flyafgange og destinationer med lethed fra Københavns Lufthavn.'
  let canonical = 'https://cphsecurityventetid.dk/afgange-cph'

  updateDocumentHead(title, metaDescription, canonical)

  const [flightData, setFlightData] = useState<FlightData[]>([])

  useEffect(() => {
    // Use a fetch or axios call to fetch the JSON data from your source
    fetch('api/journey/departures')
      .then((response) => response.json())
      .then((data) => setFlightData(data))
      .catch((error) => console.error('Error fetching data:', error))
  }, []) // Empty dependency array to ensure the effect runs only once

  const doSearchDebounced = useCallback(
    debounce(
      (searchText) => {
        let baseUrl = 'api/journey/departures'
        let callingUrl = baseUrl
        if (searchText.length > 1) {
          callingUrl = baseUrl + '?searchText=' + searchText.toLowerCase()
        }
        fetch(callingUrl)
          .then((response) => response.json())
          .then((data) => setFlightData(data))
          .catch((error) => console.error('Error fetching data:', error))
      },
      500,
      { trailing: true }
    ),
    []
  )

  const handleSearch = (e: { target: { value: string | any[] } }) => {
    if (e.target.value.length > 1) {
      doSearchDebounced(e.target.value)
    } else {
      doSearchDebounced('')
    }
  }

  return (
    <>
      <h1>Afgange Københavns Lufthavn (CPH)</h1>
      <p>
        Velkommen til vores opdaterede oversigt over afgange fra Københavns Lufthavn (CPH). Her kan du finde oplysninger om flyafgange,
        tider og destinationer for din næste rejse. Københavns Lufthavn er en travl international lufthavn med forbindelser til
        destinationer over hele verden. Vi gør det nemt for dig at planlægge din rejse ved at give dig opdaterede oplysninger om afgange.
      </p>

      <h2>Aktuelle Afgange (CPH)</h2>
      <p className='mb-4'>
        Nedenfor finder du en liste over aktuelle afgange fra Københavns Lufthavn. For at hjælpe dig viser vi de næste 12 timers flyafgange.
      </p>

      <DeparturesAd />
      <div className='mb-4'></div>

      <FlightTable flightData={flightData} direction={'Til'} handleSearch={handleSearch} />

      <p>
        Københavns Lufthavn (CPH) er din gateway til spændende destinationer over hele verden. Når du planlægger din næste flyrejse fra CPH,
        er det vigtigt at kende dine afgangsterminaler og forstå sikkerhedsprocedurerne. Denne artikel vil guide dig gennem afgange i
        Københavns Lufthavn, terminaler og de nødvendige sikkerhedsforanstaltninger.
      </p>

      <h2>Terminalerne i Københavns Lufthavn</h2>

      <p>
        CPH består af tre hovedterminaler: Terminal 1, Terminal 2 og Terminal 3. Hver terminal håndterer forskellige flyselskaber og
        destinationer. Her er en kort oversigt:
      </p>

      <ul>
        <li>
          <strong>Terminal 1:</strong> Terminal 1 er hovedsageligt for indenrigsflyvninger og nogle europæiske destinationer. Flyselskaber
          som SAS og Norwegian betjener denne terminal.
        </li>
        <li>
          <strong>Terminal 2:</strong> Terminal 2 håndterer primært europæiske flyvninger, især dem, der ikke er Schengen-medlemmer. Du
          finder flyselskaber som British Airways og Air France her.
        </li>
        <li>
          <strong>Terminal 3:</strong> Terminal 3 er CPH's største internationale terminal og dækker langdistanceflyvninger. Dette omfatter
          populære selskaber som Emirates, Delta Air Lines og Lufthansa.
        </li>
      </ul>

      <h2>Sikkerhedsprocedurer</h2>

      <p>
        Inden du går ombord på dit fly, skal du igennem sikkerhedskontrollen i lufthavnen. Sikkerhedsprocedurerne er designet for at
        beskytte passagerer og fly. Her er nogle tip til en glat gennemgang:
      </p>

      <ul>
        <li>
          <strong>Forbered dig på køen:</strong> Forvent, at der kan være køer ved sikkerhedskontrollen, især i travle perioder. Sørg for at
          ankomme i god tid.
        </li>
        <li>
          <strong>Fjern metalgenstande:</strong> Tag metalgenstande som nøgler, mønter og bælter af, inden du går igennem
          metal-detektorporten.
        </li>
        <li>
          <strong>Liquid-reglen:</strong> Væsker, geler og aerosoler skal placeres i gennemsigtige plastposer og være under 100 ml hver.
          Hver passager har normalt tilladelse til en kvart literspose.
        </li>
        <li>
          <strong>Elektronik og bærbare computere:</strong> Løft din bærbare computer og elektronik op for at blive scannet separat i de
          fleste lufthavne.
        </li>
      </ul>

      <p>
        Efter sikkerhedskontrollen vil du have adgang til toldfri shopping, restauranter og lounges, der giver dig mulighed for at slappe
        af, inden du går ombord på dit fly. Vær opmærksom på afgangstavlerne for opdaterede oplysninger om din gate og boardingtid.
      </p>

      <p>
        Københavns Lufthavn er dedikeret til at levere en sikker og behagelig rejseoplevelse for alle rejsende. Sørg for at følge de
        opdaterede sikkerhedsinstruktioner og anbefalinger fra lufthavnens personale for en problemfri afgang.
      </p>

      <p>
        Nu hvor du har en bedre forståelse af afgange i Københavns Lufthavn, kan du planlægge din rejse med tillid og forventning om en glat
        og behagelig flyvning til din ønskede destination.
      </p>
    </>
  )
}
