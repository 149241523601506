import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    const year = new Date().getFullYear()

    return (
      <div className={'container'}>
        <footer className={'d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top'}>
          <p className={'col-md-4 mb-0 text-muted'}>© CphSecurityVentetid.dk - {year}</p>

          <a
            href='/'
            className={'col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none'}
          >
            <img src='/favicon.png' width={25} height={25} alt='CphSecurityVentetid Logo' />
          </a>

          <ul className={'nav col-md-4 justify-content-end'}>
            <li className={'nav-item'}>
              <a href='mailto:hej@cphsecurityventetid.dk' className={'nav-link px-2 text-muted'} title='hej@cphsecurityventetid.dk'>
                hej@cphsecurityventetid.dk
              </a>
            </li>
          </ul>
        </footer>
      </div>
    )
  }
}
