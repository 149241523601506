import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'

export default function PasMedTilSverige() {
  useEffect(() => {
    let title = 'Skal man have pas med til Sverige?'
    let metaDescription =
      'Læs om reglerne for pas og legitimation, når du rejser fra Danmark til Sverige. Vær forberedt og rejseklar med den nødvendige dokumentation.'
    let canonical = 'https://cphsecurityventetid.dk/blog/pas-med-til-sverige'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/pas-med-til-sverige#graensekontrol-og-id-krav', text: 'Grænsekontrol og ID-krav' },
    { url: 'blog/pas-med-til-sverige#born-og-unge-rejsende', text: 'Børn og unge rejsende' },
    { url: 'blog/pas-med-til-sverige#transportmidler-og-specifikke-krav', text: 'Transportmidler og specifikke krav' },
    { url: 'blog/pas-med-til-sverige#specielle-situationer', text: 'Specielle situationer' },
    { url: 'blog/pas-med-til-sverige#konklusion', text: 'Konklusion' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Skal man have pas med til Sverige?</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>26. marts 2024</i>
            </p>
            <img
              className='img-fluid img-blog rounded mx-auto d-block'
              src='img/pas-med-til-sverige-1.webp'
              alt='Rejse til Sverige med pas via Øresundsbroen'
            />

            <p>
              Når eventyrlysten kalder, og turen går til vores skønne naboland Sverige, opstår spørgsmålet ofte: Skal man have pas med?
              Dette spørgsmål er særligt relevant i dagens åbne Europa, hvor grænserne mellem de skandinaviske lande ofte føles flydende. I
              denne artikel dykker vi ned i reglerne for rejser mellem Danmark og Sverige, så du kan pakke din taske med ro i sindet.
            </p>

            <h2 id='graensekontrol-og-id-krav'>Grænsekontrol og ID-krav</h2>
            <p>
              Sverige er, ligesom Danmark, en del af EU og Schengen-aftalen, hvilket betyder, at der overordnet set er fri bevægelighed
              mellem de to lande. Dog kan der, afhængigt af aktuelle sikkerhedssituationer eller pandemi-relaterede foranstaltninger,
              indføres midlertidige grænsekontroller.
            </p>

            <p>
              På trods af den generelle fri bevægelighed, er det vigtigt at bemærke, at alle rejsende altid bør medbringe gyldig
              legitimation. For danskere, der ønsker at besøge Sverige, betyder det, at et gyldigt pas eller nationalt ID-kort (hvis man har
              et sådant) bør være en del af rejsedokumenterne. Selvom det i mange tilfælde ikke tjekkes ved overgangen, er det essentielt at
              have det ved hånden, hvis situationen kræver det.
            </p>

            <h2 id='born-og-unge-rejsende'>Børn og unge rejsende</h2>
            <p>
              For familier på tur er det godt at huske på, at børn også skal have deres egen form for legitimation. Dette kan være et
              børnepas eller et nationalt ID-kort, hvis det er tilgængeligt. At rejse som en ung under 18 år uden ledsagelse af en forælder
              eller værge kan kræve yderligere dokumentation, såsom en underskrevet tilladelse.
            </p>

            <img
              className='img-fluid img-blog rounded mx-auto d-block'
              src='img/pas-med-til-sverige-2.webp'
              alt='Rejse til Sverige med pas via tog'
            />

            <h2 id='transportmidler-og-specifikke-krav'>Transportmidler og specifikke krav</h2>
            <p>
              Afhængigt af transportmidlet kan der være forskellige krav til identifikation. Flyselskaber, færgeselskaber og togoperatører
              kan have deres egne regler, så det er en god idé at tjekke op på disse, inden du rejser. Ved flyrejser er det som regel et
              krav at vise pas, mens færger og tog kan være mere lempelige, men stadig kræver gyldig legitimation.
            </p>

            <h2 id='specielle-situationer'>Specielle situationer</h2>
            <p>
              I særlige situationer, som for eksempel under højspændte politiske perioder eller i kølvandet på sikkerhedstrusler, kan der
              indføres strengere grænsekontrol mellem Danmark og Sverige. I disse tilfælde er det endnu vigtigere at have sit pas klar, da
              der kan forekomme grundige identitetskontroller.
            </p>

            <h2 id='konklusion'>Konklusion</h2>
            <p>
              Selvom Sverige føles tæt på og grænsepassagen ofte er smidig, er svaret på spørgsmålet klart: Ja, du bør have dit pas eller
              nationalt ID-kort med, når du rejser til Sverige. Det tjener som en sikkerhed for dig som rejsende og sikrer, at du kan bevæge
              dig frit og uden bekymringer. Så husk passet, pak bilen eller tasken, og glæd dig til skønne oplevelser i vores dejlige
              naboland mod nord!
            </p>

            <p>
              Med dette udstyr er du godt forberedt til din næste rejse til Sverige, uanset om det er for en kort dagstur eller en længere
              ferie. God tur!
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
