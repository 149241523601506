import React, { useEffect } from 'react'
import BlogCard from '../components/BlogCard'
import { updateDocumentHead } from '../utility'

export default function Blog() {
  useEffect(() => {
    let title = 'Blog - CphSecurityVentetid'
    let metaDescription =
      'Vores blog udforsker, deler tips og tricks, og giver dig indsigt i, hvordan du kan optimere din tid ved lufthavnssikkerheden.'
    let canonical = 'https://cphsecurityventetid.dk/blog'

    updateDocumentHead(title, metaDescription, canonical)
  })

  return (
    <>
      <h1 style={{ marginBottom: 10 }}>Blog</h1>
      <p>Velkommen til vores blog, hvor vi dykker ned i et af de mest afgørende elementer i din rejseoplevelse.</p>
      <p>
        For enhver rejsende er tiden i lufthavnssikkerhedskøen en afgørende faktor, der kan påvirke din hele rejse. Vi udforsker, deler tips
        og tricks, og giver dig indsigt i, hvordan du kan optimere din tid ved lufthavnssikkerheden, så du kan nyde en mere problemfri og
        afslappet rejse gennem Københavns Lufthavn. Lad os sammen udforske, hvordan du kan minimere ventetiden og maksimere din
        rejseoplevelse.
      </p>
      <p>Velkommen om bord!</p>

      <div className='row' style={{ marginBottom: 20 }}>
      <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Hvad er Jetlag? Symptomer og Tips'
            url='jetlag'
            image='jetlag-2.webp'
            imageAlt='Jetlag USA til Danmark'
            description='Få gode råd om hvordan du tackler jetlag og får mest ud af din rejse.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Stewardesse job, uddannelse og løn'
            url='stewardesse'
            image='stewardesse-1.webp'
            imageAlt='Stewardesse løn'
            description='Lær og læs om livet som stewardesse!'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Hvor meget tjener en flyveleder?'
            url='flyveleder-loen'
            image='flyveleder-loen-1.webp'
            imageAlt='Flyeveleder løn'
            description='Find ud af hvor meget man kan tjene som en flyveleder.'
          />
        </div>
        
      </div>

      <div className='row' style={{ marginBottom: 20 }}>
      <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Skal man have pas med til Sverige?'
            url='pas-med-til-sverige'
            image='pas-med-til-sverige-1.webp'
            imageAlt='Pas med til Sverige over Øresundsbroen'
            description='Få svar på spørgsmålet om legitimation man bør have på sig i Sverige.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Pilot løn'
            url='pilot-loen'
            image='pilot-loen-1.webp'
            imageAlt='To piloter i et cockpit'
            description='Find ud af hvor meget en pilot tjener her.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Må man have oplader med i håndbagage?'
            url='oplader-i-haandbagage'
            image='oplader-i-haandbagage-1.webp'
            imageAlt='Må man have oplader med i håndbagage'
            description='Få svarene på dine spørgsmål omkring opladere i hånbagagen.'
          />
        </div>
        
      </div>

      <div className='row' style={{ marginBottom: 20 }}>
      <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Hvor hurtigt flyver et fly?'
            url='hvor-hurtigt-flyver-et-fly'
            image='hvor-hurtigt-flyver-et-fly-1.webp'
            imageAlt='Hvor hurtigt flyver et fly'
            description='Find ud af hvor hurtigt et fly bevæger sig oppe i skyerne.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Hvad koster et pas?'
            url='hvad-koster-et-pas'
            image='hvad-koster-et-pas-1.jpg'
            imageAlt='Hvad koster et pas'
            description='Få indblik i omkostningerne ved at skaffe et nyt pas i Danmark.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle={`Billige rejsedestinationer ${new Date().getFullYear()}`}
            url='billige-rejsedestinationer'
            image='billige-rejsedestinationer-1.jpg'
            imageAlt='Billige rejsedestinationer'
            description='Opdag billige rejsedestinationer med vores guide til budgetvenlige rejser.'
          />
        </div>
        
      </div>

      <div className='row' style={{ marginBottom: 20 }}>
      <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Hvor mange ml må man have med i håndbagagen?'
            url='hvor-mange-ml-maa-man-have-med-i-haandbagage'
            image='vaesker-i-security-1.jpg'
            imageAlt='Væsker i security i CPH lufthavn'
            description='Forstå reglerne for håndbagage i Danmark: lær om ml-begrænsninger og tips til at pakke væsker korrekt.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Sådan veksler du penge i lufthavnen'
            url='veksle-penge-i-lufthavnen'
            image='veksle-penge-lufthavn-1.jpg'
            imageAlt='Mand i lufthavn ved dyre hæveautomater'
            description='Spar penge ved valutaveksling i lufthavne: Find de bedste steder og undgå høje gebyrer.'
          />
        </div>
        <div className='col-lg-4 col-md-12'>
          <BlogCard
            blogTitle='Hvor mange kontanter må man have på sig?'
            url='hvor-mange-kontanter-maa-man-have-paa-sig'
            image='penge-i-security.jpg'
            imageAlt='Mand med penge ved security i CPH lufthavn'
            description='Opdag de danske regler for kontanthåndtering. Få praktiske råd til sikker pengehåndtering.'
          />
        </div>
      </div>
    </>
  )
}
