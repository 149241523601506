import React from 'react';

class HomeAd extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3995505155934576";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    const adScript = document.createElement("script");
    adScript.innerHTML = "(adsbygoogle = window.adsbygoogle || []).push({});";
    document.body.appendChild(adScript);
  }

  render() {
    return (
      <ins className="adsbygoogle"
           style={{ display: "block" }}
           data-ad-client="ca-pub-3995505155934576"
           data-ad-slot="3797954177"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    );
  }
}

export default HomeAd;