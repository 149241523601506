import React from 'react'

interface Props {
  terminalName: string
  waitTime: string
}

function TerminalTime({ terminalName, waitTime }: Props) {
  return (
    <>
      <div style={{ backgroundColor: '#071143', borderRadius: 25, paddingTop: 10 }}>
        <h2 style={{ color: 'white' }}>Via {terminalName}</h2>
        <p style={{ fontSize: 50, color: '#f8ca15' }}>
          <b>{waitTime} min.</b>
        </p>
      </div>
    </>
  )
}

export default TerminalTime
