import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'
import { Link } from 'react-router-dom'

export default function FlyvelederLoen() {
  useEffect(() => {
    let title = 'Hvor meget tjener en flyveleder?'
    let metaDescription =
      'Opdag hvilken løn man kan forvente som flyveleder, hvad det kræver og hvad jobbet indebærer i denne dybdegående artikel.'
    let canonical = 'https://cphsecurityventetid.dk/blog/flyveleder-loen'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/flyveleder-loen#hvad-laver-en-flyveleder', text: 'Hvad laver en flyveleder?' },
    { url: 'blog/flyveleder-loen#en-normal-dag-for-en-flyveleder', text: 'En normal dag for en flyveleder' },
    { url: 'blog/flyveleder-loen#flyveleder-uddannelse', text: 'Flyveleder uddannelse' },
    { url: 'blog/flyveleder-loen#flyveleder-løn', text: 'Flyveleder løn' },
    { url: 'blog/flyveleder-loen#afsluttende-tanker', text: 'Afsluttende tanker' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Hvor meget tjener en flyveleder?</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>27. marts 2024</i>
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/flyveleder-loen-1.webp' alt='Løn som flyveleder' />

            <p>
              Har du nogensinde undret dig over, hvem der holder styr på alle de fly, der dagligt krydser himlen? Denne artikel kaster lys
              over flyvelederes verden – de usete helte, der sørger for, at vores rejser er sikre. Vi dykker ned i, hvad det kræver at blive
              en flyveleder, hvilken løn man kan forvente, og præcis hvad deres job indebærer.
            </p>

            <h2 id='hvad-laver-en-flyveleder'>Hvad laver en flyveleder?</h2>
            <p>
              Flyveledere spiller en afgørende rolle i luftfartssikkerheden. De overvåger og dirigerer lufttrafikken, sørger for sikker
              afstand mellem flyene i luftrummet og på landingsbanerne, og giver piloterne vigtige informationer om alt fra vejrforhold til
              nødsituationer. Dette ansvar kræver ikke kun en dyb forståelse af avancerede kontrolsystemer men også evnen til at træffe
              hurtige beslutninger under pres.
            </p>

            <h2 id='en-normal-dag-for-en-flyveleder'>En normal dag for en flyveleder</h2>
            <p>
              En flyveleders arbejdsdag er både udfordrende og varieret, og indebærer nøje koordinering af lufttrafikken for at sikre sikker
              og effektiv flyvning. Her er et overblik over nøgleelementerne i en typisk dag:
            </p>
            <ol>
              <li>
                <strong>Morgenbriefing:</strong> Dagen starter med en briefing, hvor flyvelederne opdateres om dagens forhold såsom vejr,
                trafikmængder og eventuelle særlige forhold.
              </li>
              <li>
                <strong>Arbejdspladsen:</strong> Flyveledere arbejder enten i kontroltårne ved lufthavne eller i kontrolcentre for at styre
                trafikken i det bredere luftrum. De har ansvar for at styre takeoffs, landinger og luftrumsnavigation.
              </li>
              <li>
                <strong>Konstant overvågning:</strong> En stor del af dagen går med intens overvågning af radarskærme og kommunikation med
                piloter for at dirigere flyene sikkert gennem deres sektor.
              </li>
              <li>
                <strong>Koordination og samarbejde:</strong> Effektivt teamwork er afgørende, specielt i travle perioder. Flyveledere
                arbejder tæt sammen og koordinerer med kolleger, både i tårnet og på tværs af forskellige kontrolcentre.
              </li>
              <li>
                <strong>Uforudsete hændelser:</strong> Flyveledere skal være forberedte på at håndtere uventede situationer som
                nødsituationer eller pludselige vejrændringer, hvilket kræver hurtig beslutningstagning.
              </li>
              <li>
                <strong>Afslutning af en vagt:</strong> Ved vagtens afslutning sørger flyvelederne for en grundig overlevering til det næste
                hold for at sikre kontinuitet og fortsat sikkerhed.
              </li>
              <li>
                <strong>Videreuddannelse og refleksion:</strong> Flyveledere engagerer sig løbende i videreuddannelse og reflekterer over
                dagens arbejde for konstant at forbedre deres færdigheder og viden.
              </li>
            </ol>
            <p>
              Denne daglige rutine understreger flyveledernes vitale rolle i at opretholde luftfarts sikkerheden og effektiviteten, hvor
              deres ekspertise og beslutningstagning er afgørende for millioner af passagerers sikkerhed hver dag.
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/flyveleder-loen-3.webp' alt='Flyveleder i kontroltårn' />

            <h2 id='flyveleder-uddannelse'>Flyveleder uddannelse</h2>
            <p>
              Vil du være en del af denne elitegruppe? Vejen til at blive flyveleder begynder med en specialiseret uddannelse. I Danmark er
              det Trafik-, Bygge- og Boligstyrelsen, der står for rekrutteringen, og uddannelsen foregår ved Naviair's uddannelsescenter.
              Uddannelsen er intens og indeholder både teoretiske og praktiske elementer, hvor kandidater lærer alt fra luftfartslovgivning
              til anvendelse af radar- og kommunikationssystemer.
            </p>
            <p>
              Det er vigtigt at bemærke, at optagelseskravene er strenge. Udover akademiske kvalifikationer, skal ansøgere gennemgå en række
              tests og interviews, der måler deres evner inden for områder som rumlig orientering, problemløsning og stresshåndtering.
            </p>

            <h2 id='flyveleder-løn'>Flyveleder løn</h2>
            <p>
              Lønforholdene for en flyveleder kan variere betydeligt afhængigt af arbejdspladsen. For flyveledere i almindelige lufthavne
              starter lønnen typisk omkring 30.000 DKK om måneden. Med erfaring og avancement i karrieren kan dette beløb stige betydeligt,
              hvor erfarne flyveledere kan tjene over 60.000 DKK om måneden.
            </p>

            <img className='img-fluid img-blog rounded mx-auto d-block' src='img/flyveleder-loen-2.webp' alt='Flyveleder løn' />

            <p>
              For flyveledere inden for forsvaret ser lønstrukturen anderledes ud. Her begynder startlønnen for en færdiguddannet sergent
              ved omkring 50.000 DKK om måneden (brutto), som inkluderer grundløn, militærtillæg, variable ydelser og funktionstillæg. Efter
              18 års tjeneste kan denne løn stige til omkring 100.000 DKK om måneden (brutto). Uddannelsen til flyveleder inden for
              forsvaret varer mellem 2-3 år, afhængigt af om kandidaten allerede er uddannet sergent, og efter endt uddannelse følger en
              tjenestepligt på 6 år.
            </p>

            <p>
              Alt i alt ligger lønnen for flyveledere tæt opad <a href='/blog/pilot-loen'>pilot løn</a>. Dog har man som flyveleder ikke
              så mange mulige arbejdspladser, hvor piloter kan skifte imellem flyselskaber. Dog mister piloter deres senioritet og skal
              oftest starte fra bunden.
            </p>

            <h2 id='afsluttende-tanker'>Afsluttende tanker</h2>
            <p>
              At være flyveleder er utvivlsomt en af de mest udfordrende og givende karriereveje inden for luftfartsbranchen. Det kræver et
              unikt sæt af færdigheder og personlige egenskaber at trives i dette miljø. For de rette individer tilbyder det dog en mulighed
              for at spille en afgørende rolle i sikkerheden og effektiviteten af global luftfart, samt en lønpakke, der anerkender denne
              vitale funktion.
            </p>
            <p>
              Har du, hvad der kræves for at blive en flyveleder? Er du drevet af et ønske om at sikre, at millioner af rejsende kommer
              sikkert frem til deres destinationer? Hvis ja, kunne denne karrierevej være den perfekte pasform for dig.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
