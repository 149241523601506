export function updateDocumentHead(title: string, description: string, canonicalHref: string): void {
  document.title = title

  let metaDescription = document.head.querySelector('meta[name="description"]') as HTMLMetaElement
  if (metaDescription) {
    metaDescription.content = description
  } else {
    metaDescription = document.createElement('meta')
    metaDescription.name = 'description'
    metaDescription.content = description
    document.head.appendChild(metaDescription)
  }

  let linkCanonical = document.head.querySelector('link[rel="canonical"]') as HTMLLinkElement
  if (linkCanonical) {
    linkCanonical.href = canonicalHref
  } else {
    linkCanonical = document.createElement('link')
    linkCanonical.rel = 'canonical'
    linkCanonical.href = canonicalHref
    document.head.appendChild(linkCanonical)
  }
}
