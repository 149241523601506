import React, { Component } from 'react'
import TerminalTime from '../components/TerminalTime'
import { updateDocumentHead } from '../utility'
import HomeAd from '../components/ads/HomeAd'

interface Props {
  terminal2: string
  terminal3: string
  lastUpdated?: string
}

interface State {
  wait: Props
}

export class Home extends Component<{}, State> {
  state: State = {
    wait: {
      terminal2: '-',
      terminal3: '-',
      lastUpdated: undefined
    }
  }

  private interval: NodeJS.Timeout | null = null

  async componentDidMount() {
    let title = 'Live ventetid ved Security i Københavns Lufthavn (CPH) - CphSecurityVentetid'
    let metaDescription =
      'Aktuel ventetid i sikkerhedskontrol/security for Terminal 2 og Terminal 3 i Københavns Lufthavn (CPH). Planlæg din rejse med nøjagtige informationer.'
    let canonical = 'https://cphsecurityventetid.dk'

    updateDocumentHead(title, metaDescription, canonical)

    await this.fetchData()

    this.interval = setInterval(async () => {
      await this.fetchData()
    }, 30 * 1000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  fetchData = async () => {
    const response = await fetch('api/waittime')
    const data = await response.json()
    this.setState({ wait: data })
  }

  render() {
    const { terminal2, terminal3, lastUpdated } = this.state.wait

    const date = new Date(lastUpdated ?? new Date())

    const utcOffsetMinutes = date.getTimezoneOffset()
    const utcOffsetHours = utcOffsetMinutes / 60

    const utcPlusOneString = ' (UTC +01:00)'
    const utcPlusTwoString = ' (UTC +02:00)'
    let utcOffsetString = utcPlusOneString
    if (-utcOffsetHours === 1) {
      utcOffsetString = utcPlusOneString
    } else if (-utcOffsetHours === 2) {
      utcOffsetString = utcPlusTwoString
    }

    const formattedDate =
      date.toLocaleString('da-DK', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      }) + utcOffsetString

    return (
      <div className='container'>
        <center>
          <h1 className='fw-bolder mb-5'>Aktuel Security Ventetid i Københavns Lufthavn</h1>

          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <TerminalTime terminalName='Terminal 2' waitTime={terminal2} />
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 column-margin-1'>
                <TerminalTime terminalName='Terminal 3' waitTime={terminal3} />
              </div>
            </div>
          </div>

          <p style={{ marginBottom: 1 }}>
            <u>Sidst opdateret (last updated)</u>
          </p>
          <p style={{ fontFamily: "'Roboto', sans-serif" }}>{lastUpdated ? formattedDate : '-'}</p>
          <p style={{ marginBottom: '4em' }}>
            <i style={{ fontSize: 12 }} className='fa fa-circle text-danger-glow blink'></i>
          </p>
        </center>

        <HomeAd />

        <section style={{ marginTop: 80 }} className='bg-light py-5'>
          <div className='container px-5'>
            <div className='row gx-5'>
              <div className='col-12 '>
                <div className='my-3'>
                  <h2 className='display-6 fw-bolder'>
                    <center>
                      <span className='d-inline'>Optimér din ventetid og undgå kø</span>
                    </center>
                  </h2>
                </div>
              </div>
            </div>

            <div className='row gx-5'>
              <div className='col-md-6 col-sm-12 order-2 order-md-1 column-margin-2'>
                <div className='my-3'>
                  <p className='text-muted'>
                    Er du træt af at sidde i lufthavnen og vente i timevis ved sikkerhedskontrollen? Velkommen til Cph Security Ventetid,
                    din ultimative kilde til at minimere ventetiden i Københavns Lufthavn.
                  </p>
                  <p className='text-muted'>
                    Når du rejser fra Kastrup Lufthavn, er ventetid ved sikkerhedskontrollen en af de mest stressende faktorer. Men nu kan
                    du tage kontrol over din rejseoplevelse ved at få øjeblikkelig information om ventetiden i Københavns Lufthavn Security.
                  </p>
                  <p className='text-muted'>
                    På denne side finder du nøjagtige og opdaterede oplysninger om ventetider ved sikkerhedskontrollen i Københavns
                    Lufthavn. Vores mål er at give dig den mest pålidelige indsigt, så du kan planlægge din tid effektivt og undgå
                    unødvendig stress.
                  </p>
                  <p className='text-muted'>
                    Uanset om du er en erfaren rejsende eller førstegangsbesøgende i lufthavnen, er vores ressourcer her for at hjælpe dig
                    med at navigere gennem sikkerhedskontrollen med lethed. Vi tilbyder også nyttige tips og rådgivning om, hvordan du kan
                    minimere ventetiden og gøre din rejse mere behagelig.
                  </p>
                  <p className='text-muted'>God rejse!</p>
                  <div className='d-flex justify-content-center fs-2 gap-4'>{/* Social icons here */}</div>
                </div>
              </div>
              <div className='col-md-6 col-sm-12 order-1 order-md-2'>
                <div className='my-3'>
                  <center>
                    <img className='img-fluid rounded' alt='Kastrup Lufthavn Ventetid' src='/img/cphsecurityventetid-5.webp' />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
