import React, { useEffect } from 'react'
import { updateDocumentHead } from '../../utility'
import BlogSidebar from '../../components/BlogSidebar'

export default function HvadKosterEtPas() {
  useEffect(() => {
    let title = `Hvad koster et pas i ${new Date().getFullYear()}?`
    let metaDescription =
      'Få indblik i omkostningerne ved at skaffe et nyt pas i Danmark. Opdag priserne, processen og muligheder for hurtig paslevering. Få svar på dine spørgsmål om pasgebyrer.'
    let canonical = 'https://cphsecurityventetid.dk/blog/hvad-koster-et-pas'

    updateDocumentHead(title, metaDescription, canonical)
  })

  const sidebarLinks = [
    { url: 'blog/hvad-koster-et-pas#hvad-er-et-pas', text: 'Hvad er et pas?' },
    { url: 'blog/hvad-koster-et-pas#priser', text: 'Priser for pas i Danmark' },
    { url: 'blog/hvad-koster-et-pas#hvordan', text: 'Hvordan ansøger man om et nyt pas?' },
    { url: 'blog/hvad-koster-et-pas#hvor-lang-tid', text: 'Hvor lang tid tager det at få et nyt pas?' },
    { url: 'blog/hvad-koster-et-pas#betaling', text: 'Betalingsmetoder og gebyrer' },
    { url: 'blog/hvad-koster-et-pas#faq', text: 'Ofte stillede spørgsmål' },
    { url: 'blog/hvad-koster-et-pas#konklusion', text: 'Konklusion' }
  ]

  return (
    <>
      <div className='container'>
        <div className='row'>
          <BlogSidebar links={sidebarLinks} />
          <div className='col-lg-8 order-lg-1 order-2'>
            <h1 style={{ marginBottom: 10 }}>Hvad koster et pas?</h1>
            <p>
              <i className='fa-solid fa-user'></i> Rasmus - <i>27. januar 2024</i>
            </p>

            <p>
              Et pas er et vigtigt dokument, der giver dig mulighed for at rejse internationalt og identificere dig selv som statsborger i
              dit hjemland. Hvis du er dansk statsborger og planlægger at rejse uden for Danmarks grænser, er det afgørende at forstå, hvad
              det koster at få et nyt pas i Danmark. Denne artikel vil guide dig gennem processen og omkostningerne ved at anskaffe et nyt
              pas, samt besvare almindelige spørgsmål omkring pasomkostninger.
            </p>

            <img
              className='img-fluid img-blog-square rounded mx-auto d-block'
              src='img/hvad-koster-et-pas-1.jpg'
              alt={`Hvad koster et pas ${new Date().getFullYear()}`}
            />

            <h2 id='hvad-er-et-pas'>Hvad er et pas?</h2>
            <p>
              Før vi dykker ned i priserne, lad os starte med at definere, hvad et pas egentlig er. Et pas er et officielt dokument, udstedt
              af den danske stat, der bekræfter din identitet som dansk statsborger. Det er også et vigtigt dokument, når du rejser til
              andre lande, da det giver dig adgang til at krydse grænser og indrejse i andre lande. Et pas indeholder oplysninger som dit
              navn, foto og pasnummer samt udstedelsesdato og udløbsdato.
            </p>

            <h2 id='priser'>Priser for pas i Danmark</h2>
            <h3>Almindelige pasomkostninger</h3>
            <p>
              Prisen for et almindeligt pas i Danmark kan variere afhængigt af din alder og gyldighedsperioden for passet. Her er de
              gældende priser i {new Date().getFullYear()}:
            </p>

            <ul>
              <li>
                <p>For børn (0 - 11 år) koster et børnepas med 5 års gyldighed normalt 150 DKK.</p>
              </li>
              <li>
                <p>For børn (12 - 17 år) koster et børnepas med 5 års gyldighed normalt 181 DKK.</p>
              </li>
              <li>
                <p>For voksne (18 år - folkepensionsalderen) koster et standardpas med 10 års gyldighed 893 DKK.</p>
              </li>
              <li>
                <p>For voksne (i folkepensionsalderen) koster et standardpas med 10 års gyldighed 381 DKK.</p>
              </li>
            </ul>

            <p>
              Det er vigtigt at bemærke, at disse priser kan ændre sig over tid, så det er en god idé at tjekke den seneste prisinformation
              på den officielle hjemmeside for Borgerservice i Danmark.
            </p>

            <h3>Ekspresgebyrer og levering</h3>
            <p>
              Hvis du har brug for dit pas hurtigere end den normale behandlingstid, kan du vælge ekspreslevering mod en ekstra omkostning.
              Prisen for ekspresgebyret varierer, men det kan være omkring 500-600 DKK. Med ekspreslevering kan du normalt få dit pas inden
              for 1-3 hverdage, hvilket er ideelt, hvis du har en kommende rejse.
            </p>

            <h2 id='hvordan'>Hvordan ansøger man om et nyt pas?</h2>
            <p>
              Ansøgning om et nyt pas i Danmark er en enkel proces, men det kræver nogle vigtige trin. Her er en trin-for-trin-guide til at
              hjælpe dig med at ansøge om et nyt pas:
            </p>

            <ol>
              <li>
                <p>
                  <strong>Forbered dine dokumenter:</strong> Du skal have gyldig billedlegitimation og eventuelle tidligere pas klar.
                </p>
              </li>
              <li>
                <p>
                  <strong>Book en tid:</strong> Du skal bestille en tid til pasansøgning i Borgerservice eller på politistationen.
                </p>
              </li>
              <li>
                <p>
                  <strong>Mød op personligt:</strong> Gå til din aftalte tidspunkt og sted for at ansøge om dit pas personligt.
                </p>
              </li>
              <li>
                <p>
                  <strong>Betaling:</strong> Betal pasgebyret og eventuelle ekspresgebyrer på stedet.
                </p>
              </li>
              <li>
                <p>
                  <strong>Vent på behandling:</strong> Afvent behandlingen af din ansøgning, hvilket normalt tager et par uger.
                </p>
              </li>
              <li>
                <p>
                  <strong>Hent dit pas:</strong> Når dit pas er klar, modtager du en besked om at hente det på det sted, hvor du ansøgte om
                  det.
                </p>
              </li>
            </ol>

            <h2 id='hvor-lang-tid'>Hvor lang tid tager det at få et nyt pas?</h2>
            <p>
              Den gennemsnitlige behandlingstid for et nyt pas i Danmark er normalt 1-3 uger, afhængigt af sæsonen og antallet af
              ansøgninger. Hvis du har brug for dit pas hurtigere, kan du vælge ekspreslevering, som normalt giver dig adgang til dit pas
              inden for 1-3 hverdage. Det er vigtigt at tage højde for denne tidslinje, når du planlægger din rejse, så sørg for at ansøge i
              god tid.
            </p>

            <h2 id='betaling'>Betalingsmetoder og gebyrer</h2>
            <p>
              Når du ansøger om et nyt pas i Danmark, kan du normalt betale pasgebyret med kontanter eller de mest almindelige kredit- og
              betalingskort. Husk at tjekke med det pågældende Borgerservice eller politistation for at bekræfte de accepterede
              betalingsmetoder.
            </p>

            <h3>Pasfornyelse</h3>
            <p>
              Husk, at dit pas har en udløbsdato, og det er vigtigt at forny det i tide, hvis du planlægger at fortsætte med at rejse
              internationalt. Priserne for pasfornyelse vil variere afhængigt af passets gyldighedsperiode og din alder, så sørg for at
              tjekke de aktuelle priser, når tiden nærmer sig for fornyelse.
            </p>

            <img className='img-fluid img-blog-square rounded mx-auto d-block' src='img/hvad-koster-et-pas-2.jpg' alt='Spørgsmål til pas' />

            <h2 id='faq'>Ofte stillede spørgsmål</h2>

            <h3>Hvor lang tid tager det at få et pas i Danmark?</h3>
            <p>
              Tiden det tager at få et pas i Danmark kan variere afhængigt af flere faktorer, herunder den type pas, du ansøger om, og hvor
              travlt det er på det pågældende tidspunkt. Generelt set kan du forvente følgende omtrentlige behandlingstider for pas i
              Danmark:
            </p>
            <ol>
              <li>
                <p>
                  <b>Almindeligt pas (voksen):</b> Typisk tager det omkring 10 arbejdsdage at få et almindeligt pas udstedt, efter at din
                  ansøgning er blevet godkendt. Du kan muligvis få det hurtigere, hvis du betaler ekstra for en hurtig behandling.
                </p>
              </li>
              <li>
                <p>
                  <b>Børnepas:</b> Behandlingstiden for et børnepas er normalt den samme som for et almindeligt voksenpas, dvs. omkring 10
                  arbejdsdage.
                </p>
              </li>
              <li>
                <p>
                  <b>Nødpas:</b> Hvis du har brug for et nødpas i en nødsituation, kan det udstedes inden for få timer, forudsat at du
                  opfylder betingelserne og har de nødvendige dokumenter.
                </p>
              </li>
            </ol>
            <p>
              Det er vigtigt at bemærke, at disse behandlingstider kan ændre sig, og det er en god idé at tjekke de seneste opdaterede
              oplysninger på den danske statsforvaltnings hjemmeside eller kontakte din lokale politistation eller borgerservicekontor for
              at få de mest nøjagtige oplysninger og vejledning i forhold til din specifikke situation. Det kan også være en god idé at
              ansøge om et pas i god tid, især hvis du har planer om at rejse i nær fremtid.
            </p>

            <h3>Kan jeg få mit pas hurtigere?</h3>
            <p>
              Ja, du kan vælge ekspreslevering, der normalt leverer dit pas inden for 1-3 hverdage. Dette er ideelt, hvis du har en hurtig
              forestående rejseplan.
            </p>

            <h3>Hvad er forskellen mellem et almindeligt pas og et børnepas?</h3>
            <p>
              Et almindeligt pas er beregnet til voksne (18 år og ældre) og har normalt en gyldighedsperiode på 10 år. Et børnepas er for
              børn under 18 år og har en kortere gyldighedsperiode på normalt 5 år. Priserne varierer i overensstemmelse hermed.
            </p>

            <h3>Er der undtagelser eller fritagelser fra pasgebyret?</h3>
            <p>
              Visse grupper, såsom flygtninge og asylansøgere, kan være fritaget for pasgebyret. Du bør kontakte din lokale Borgerservice
              for at få yderligere information og vejledning i sådanne tilfælde.
            </p>

            <h2 id='konklusion'>Konklusion</h2>
            <p>
              At forstå omkostningerne ved at få et nyt pas i Danmark er afgørende, hvis du planlægger internationale rejser. Priserne
              varierer afhængigt af din alder, gyldighedsperioden og eventuelle ekspresgebyrer, du måtte vælge. Sørg for at forny dit pas i
              tide, når det udløber, og planlæg din ansøgning i overensstemmelse hermed. Med de rette oplysninger og forberedelser kan du
              nemt navigere i processen og få dit pas til rette tid.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
